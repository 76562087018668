import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  "en-US": {
    translation: {
      SERVICES: "SERVICES",
      ABOUT: "ABOUT",
      TESTING: "TESTING",
      APPLICATIONS: "APPLICATIONS",
      "DATA SCIENTISTS": "DATA SCIENTISTS",
      "MICROSOFT DYNAMICS AX": "MICROSOFT DYNAMICS AX",
      REFERENCES: "REFERENCES",
      LEGAL: "Legal Notice",
      homepage1_header: "Accelerate your digital genesis & transformation",
      homepage_features_41: "Benefit from 20+ years of experience of experts",
      homepage_features_42:
        "Enable your users with world class digital products",
      homepage_features_43: "Empower your teams to be nimble",
      homepage_features_44: "Focus on your core business",
      homepage1_service_button_name: "SEE OUR SERVICES  ",
      homepage1_button_arrow: "»",
      homepage1_header_mid_white:
        "Work with us to establish centers of excellence to ",
      homepage1_header_mid_highlight: "empower your people",
      homepage1_header_mid2_white: "Concentrate",
      homepage1_header_mid2_white2: "on",
      homepage1_header_mid2_highlight: "your know how",
      homepage_features_31: "We integrate into your internal tools & processes",
      homepage_features_32: "Focus on your customers with increased agility",
      homepage_features_33:
        "Our proven methodologies enable you to deliver high quality digital products to your customers",
      homepage1_reference_button_name: "SEE OUR REFERENCES  »",
      homepage1_bottom_text: "Enable your people to",
      homepage1_bottom_text_highlight: "focus on innovation",
      homepage2_text_header: "20 years of experience",
      homepage2_text_header_highlight: "<br>at your service",
      homepage2_text_para_highlight:
        "Established on 4 continents and 20 countries,",
      homepage2_text_para_white:
        "we favor long-term partnerships with our customers",
      homepage1_excerpt_white:
        "We build <br> digital products to enable companies on their journey of",
      homepage1__excerpt_highlight: "digital transformation",
      "Legal Notice": "Legal Notice",
      services1_excerpt_white: "Offer",
      services1_excerpt_highlight: "world-class digital experiences",
      services1_excerpt_end: "to your customers",
      service1_header: "Testing & QA",
      service_features_41:
        "Ensure a seamless experience for your users across devices, operating systems & browsers",
      service_features_42:
        "The number of combinations of devices, operating systems & browsers you need to test is vast",
      service_features_43:
        "We test on over 70 combinations, and save your team 20-30% of their time",
      service_features_44: "Your teams can focus on your core business",
      services1_mid_header: "2 modes of operations",
      service1_process1_header: "Your website or application ",
      service1_process1_header_highlight: "already exists",
      service1_process1_text:
        "We perform comprehensive technical and ergonomic testing of your digital products. We analyze and identify defects. We help you find the right solutions.",
      service1_process2_header: "Your website or application is ",
      service1_process2_header_highlight: "in the development phase",
      service1_process2_text:
        "We directly integrate into your development process. The tests are carried out as your product evolves. Our engineers triage defects, and fix them as soon as they are diagnosed.",
      service1_lower_text_white:
        "Our teams can fix high-impact bugs, while minimizing invasive changes",
      service1_lower_text_highlight:
        "We help ensure your users have a high quality experience",
      service2_excerpt_white: "We build",
      service2_excerpt_highlight: "delightful, robust & performant",
      service2_excerpt_end: "mobile apps",
      service2_header: "iOS & Android App Development",
      service_features_31:
        "Create high-quality apps that your users will enjoy using",
      service_features_32:
        "We have a highly skilled team of iOS developers, including those who’ve worked at Apple",
      service_features_33:
        "Our team uses the latest technologies from Google to build Android apps that feel at home across the range of Android devices",
      service2_lower_text_white:
        "From design to production, our developers follow",
      service2_lower_text_highlight:
        "best practices to build apps of the highest quality",
      The: "The",
      service3_excerpt_white: "Gather data, gain insight, and",
      service3_excerpt_highlight: "increase your performance",
      service3_header: "Data Scientists",
      service3_para1_bangalore_uni: " Indian Institute of Science",
      service3_para1_end:
        "is home to some of the most advanced labs in data science, big data, and machine learning.",
      service3_para2: "The best PHDs in the field study & teach there.",
      service3_para3:
        "In partnership with IISc, we set up centers of expertise specialized for you.",
      service3_para4:
        "Doctoral students in mathematics, statistics, or specialized in other technologies, formulate advanced algorithms. By precisely understanding your problem, these algorithms make it possible to extract insight from your data.",
      service4_header: "Dynamics AX Competence Center",
      service4_para:
        "Take advantage of our 150+ engineers to optimize the use of",
      service4_para_highlight: "Microsoft Dynamics AX.",
      service4_li1: "Specific development adapted to the needs of your company",
      service4_li2:
        "Consulting-parameter setting to make the most of the capabilities of your ERP",
      service4_li3: "Data migration to your new platform",
      service4_li4:
        "Data collection and analysis to help you with your strategy",
      service4_lower_highlight:
        "Well used, this reduces the risk of error and becomes a major asset for your success.",
      service4_para_ul: "Our team can assist with:",
      service4_excerpt_white: "Boost",
      service4_excerpt_highlight: "your business performance",
      reference_header: "accompanies the success of its customers",
      reference_flexbox_text:
        "We build lasting relationships with each of our clients.",
      Flexibility: "Flexibility",
      Reactivity: "Reactivity",
      Adaptability: "Adaptability",
      Transparency: "Transparency",
      reference_list_text:
        "Our long-term partnership policy contributes to your growth in various business sectors.",
      reference_li1: "Major French Luxury Brands",
      reference_li2: "International Banks",
      reference_li3: "Communication Agencies",
      reference_li4: "startups",
      reference_li5: "multinationals",
      reference_lower_text:
        "Contact us to know our references and deepen our areas of expertise.",
      md: "{{md}}"
    }
  },
  fr: {
    translation: {
      SERVICES: "SERVICES",
      ABOUT: "SOCIÉTÉ",
      TESTING: "TESTING",
      APPLICATIONS: "APPLICATIONS",
      "DATA SCIENTISTS": "DATA SCIENTISTS",
      "MICROSOFT DYNAMICS AX": "MICROSOFT DYNAMICS AX",
      REFERENCES: "RÉFÉRENCES",
      LEGAL: "Mentions légales",
      homepage1_header: "accélère votre croissance",
      homepage1_service_button_name: `VOIR NOS SERVICES  `,
      homepage1_button_arrow: "»",
      homepage1_header_mid_white:
        "Nous instaurons ensemble, des centres de compétences,<br>dédiés à l'",
      homepage1_header_mid_highlight:
        "optimisation de vos Systèmes d'Information ",
      homepage1_header_mid_end: "(SI).",
      homepage1_header_mid2_white: "Concentrez-vous",
      homepage1_header_mid2_white2: "sur",
      homepage1_header_mid2_highlight: "votre savoir-faire",
      homepage1_reference_button_name: "VOIR NOS RÉFÉRENCES  »",
      homepage1_bottom_text: "Dégagés de ces tâches,",
      homepage1_bottom_text_highlight:
        "vos concepteurs peuvent se consacrer à l'innovation.",
      homepage2_text_header: "20 années d'expérience",
      homepage2_text_header_highlight: "<br>à votre service",
      ".": ".",
      homepage2_text_para_highlight: "Implantés sur 4 continents et 20 pays,",
      homepage2_text_para_white:
        "nous privilégions le partenariat à long terme avec nos clients, pour promouvoir de manière optimum votre image de marque.", //
      homepage1_excerpt_white:
        "Adaptez vos <br>Technologies de l'Information (IT)<br> à la",
      homepage1__excerpt_highlight: "vision de votre marque",
      "Legal Notice": "Mentions légales",
      homepage_features_41:
        "Profitez de <br>l’expérience des plus <br>grands data <br>scientists.",
      homepage_features_42:
        "Offrez à vos clients <br>une utilisation<br>optimale de vos <br>outils digitaux.",
      homepage_features_43:
        "Coordonnez les <br>dispositifs <br>informatiques de <br>gestion de votre <br>entreprise.",
      homepage_features_44: "Et augmentez votre <br>création de valeur.",
      homepage_features_31: "Accélérez votre processus interne.",
      homepage_features_32:
        "Nous intégrons directement vos équipes de développement de releases.",
      homepage_features_33:
        "Nos méthodologies de tests éprouvées, listent les dysfonctionnements de vos sites et applications, pour établir une réponse rapide et adaptée.",
      services1_excerpt_white: "Proposez une",
      services1_excerpt_highlight: "expérience digitale <br> optimale",
      services1_excerpt_end: "à vos clients",
      service1_header: "Activité de testing",
      service_features_41:
        "Adaptez vos produits informatiques quelques soient le support, le système d’exploitation ou le navigateur, pour vos internautes.",
      service_features_42:
        "Le nombre de tests à effectuer est exponentiel avant la sortie de vos releases.",
      service_features_43:
        "Nous les réalisons pour vous, sur plus de 70 plateformes. 20 à 30% du temps de production est économisé.",
      service_features_44:
        "Vos équipes peuvent se centrer sur la croissance de votre activité.",
      services1_mid_header: "2 Procédés",
      service1_process1_header: "Votre site ou application<br>est ",
      service1_process1_header_highlight: "déjà existant.",
      service1_process1_text:
        "Nous réalisons des essais complets, techniques et ergonomiques de vos produits numériques.<br>Nous analysons et identifions les dysfonctionnements.<br>Nous trouvons ensemble les solutions adaptées.",
      service1_process2_header: "Votre site ou application<br>est ",
      service1_process2_header_highlight: "en phase de développement",
      service1_process2_text:
        "Nous proposons une intégration directe à votre processus de production.<br>Les tests sont réalisés tout au long de la conception.<br>Nos ingénieurs résolvent les anomalies dès diagnostic.",
      service1_lower_text_white:
        "Nos experts corrigent les bugs les plus courants,<br> sans toucher aux fonctionnalités.",
      service1_lower_text_highlight:
        "Nous garantissons une Assurance Qualité (QA) conforme.",
      service2_excerpt_white: "Choisissez",
      service2_excerpt_highlight: "l'expérience<br>",
      service2_excerpt_end: "pour réaliser vos Apps.",
      service2_header: "Développement d’Apps<br>iOS & Android",
      service_features_31:
        "Créez des applications de qualité pour vos utilisateurs",
      service_features_32:
        "Le développement d’Apps sur iOS est assuré par une équipe d’ingénieurs ayant travaillé pour Apple.",
      service_features_33:
        "Nos techniciens utilisent tous les langages de programmation existants sous Android.",
      service2_lower_text_white:
        "De la conception à la production, nos développeurs<br>et techniciens, produisent",
      service2_lower_text_highlight:
        "des logiciels garantis aux normes d’Assurance Qualité (QA).",
      service3_excerpt_white: "Récupérez des données<br> pertinentes et",
      service3_excerpt_highlight: "augmentez<br> votre rendement",
      The: "L’",
      service3_para1_bangalore_uni:
        "Université de Science et Technologie de Bangalore",
      service3_para1_end:
        "est l’un des pôles les plus avancés dans le traitement et l’analyse des mégadonnées.",
      service3_para2:
        "Les meilleurs PHD dans le domaine sont formés dans de cette institution, et les plus grands acteurs du big data s’y retrouvent.",
      service3_para3:
        "En partenariat avec l’Université de Bangalore, nous mettons en place des pôles de compétences spécialisés dans votre secteur.",
      service3_para4:
        "Nos doctorants en mathématiques, en statistiques, ou spécialisés dans d’autres technologies, formulent des algorithmes avancées.<br> En définissant avec précision votre recherche, nos algorithmes permettent d’extraire les données génératrices de votre croissance.",
      service4_header: "Centre de compétences de<br>Dynamics AX",
      service4_para:
        "Profitez de nos 150 ingénieurs pour optimiser l’utilisation de",
      service4_para_highlight: "Microsoft Dynamics AX.",
      service4_li1:
        "Du développement spécifique adapté aux besoins de votre entreprise.",
      service4_li2:
        "Du consulting-paramétrage pour exploiter au mieux les capacités de votre ERP.",
      service4_li3:
        "De la migration de données vers votre nouvelle plateforme.",
      service4_li4:
        "De la collecte et analyse de données pour vous aider dans votre stratégie de décisions.",
      service4_lower_highlight:
        "Bien utilisé, ce progiciel réduit conséquemment le risque d’erreur et devient un atout majeur de votre réussite.",
      service4_para_ul: "Nous proposons :",
      service4_excerpt_white: "Renforcez les",
      service4_excerpt_highlight: "performances<br>de votre entreprise",
      service3_header: "Data Scientists",
      reference_header: "accompagne la réussite de ses clients",
      reference_flexbox_text:
        "Nous construisons des relations pérennes avec chacun de nos clients.",
      Flexibility: "Flexibilité",
      Reactivity: "Réactivité",
      Adaptability: "Adaptabilité",
      Transparency: "Transparence",
      reference_list_text:
        "Notre politique de partenariat à long terme participe à votre croissance dans différents secteurs d’activité.",
      reference_li1: "Grandes marques de Luxe Françaises",
      reference_li2: "Banques Internationales",
      reference_li3: "Agences de communication",
      reference_li4: "Startups",
      reference_li5: "Multinationales",
      reference_lower_text:
        "Prenez contact avec nous pour connaitre nos références et approfondir nos domaines de compétences.",
      md: "{{md}}"
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: true
    },
    fallbackLng: {
      default: ["en-US"]
    }
  });

export default i18n;
