import { Link } from "gatsby";
import i18n from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { Container } from "rsuite";
import MenuIcon from "../../assets/menu_icon.png";
import logo from "../../assets/surya-digital-logo.png";

interface LayoutNavProps {
  handleClick: () => void;
  transition: boolean;
}
class LayoutNav extends React.Component<LayoutNavProps, {}> {
  constructor(props: LayoutNavProps) {
    super(props);
  }

  public render() {
    return (
      <Container
        style={{
          alignContent: `center`,
          display: `flex`,
          height: `100%`,
          position: `absolute`,
          width: `100%`,
          zIndex: 1000001
        }}
      >
        <div
          className="layout-header"
          style={{ width: `100%`, position: `inherit`, maxWidth: `100%` }}
        >
          {" "}
          <div
            className="surya-logo-div"
            style={{ position: `absolute`, top: `47px`, left: `17px` }}
          >
            {" "}
            <a
              href="/"
              className="navbar-logo-left"
              onClick={() => {
                if (typeof localStorage !== "undefined") {
                  localStorage.setItem("scrollPosition", (0).toString());
                }
              }}
            >
              <img
                src={logo}
                style={{
                  height: `100%`,
                  width: `100%`
                }}
                alt="Surya Digital Logo"
              />{" "}
            </a>
          </div>{" "}
          <button
            className={
              this.props.transition
                ? "navbar-sidebar-button-hide"
                : "navbar-sidebar-button"
            }
            onClick={() => {
              this.props.handleClick();
            }}
            style={
              this.props.transition
                ? { marginRight: `0`, right: `45px` }
                : { marginRight: `0px`, display: `none` }
            }
          >
            {!this.props.transition && (
              <img
                src={MenuIcon}
                alt="hamburger open"
                style={{
                  width: `100%`
                }}
              />
            )}
            {this.props.transition && (
              <div className="layout-narrow-open-hamburger-logo">×</div>
            )}
          </button>
        </div>
        <ul className="layout-nav-ul">
          <li className="layout-nav-li">
            <a
              className="layout-nav-link"
              href="/about"
              style={{ color: `inherit`, textDecoration: `inherit` }}
              onClick={() => {
                if (typeof localStorage !== "undefined") {
                  localStorage.setItem("scrollPosition", (0).toString());
                }
              }}
            >
              <Trans i18nKey="ABOUT" />
            </a>
          </li>
          <li className="layout-nav-li">
            <a
              className="layout-nav-link"
              href="/services"
              style={{
                color: `inherit`,
                textDecoration: `inherit`
              }}
              onClick={() => {
                if (typeof localStorage !== "undefined") {
                  localStorage.setItem("scrollPosition", (0).toString());
                }
              }}
            >
              <Trans i18nKey="SERVICES" />
            </a>
          </li>
          <div
            className="layout-nav-li"
            style={{ width: `100%`, borderBottom: `none` }}
          >
            <button
              className={"lang-buton-navbar-narrow"}
              onClick={() => {
                i18n.changeLanguage("en-US");
                this.props.handleClick();
              }}
            >
              ENGLISH
            </button>
            <button
              className="lang-buton-navbar-narrow"
              onClick={() => {
                i18n.changeLanguage("fr");
                this.props.handleClick();
              }}
            >
              FRANÇAIS
            </button>
          </div>
        </ul>
      </Container>
    );
  }
}

export default LayoutNav;
