import { Link, navigate } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";
import ScrollableAnchor from "react-scrollable-anchor";
import { NavLink } from "reactstrap";
import * as smoothscroll from "smoothscroll-polyfill";
import ArrowBottom from "../../assets/arrow-bottom.png";
import Logo from "../../assets/surya-digital-logo.png";
import "./layout.css";

interface LayoutFooterStates {
  pathName: string;
}

class LayoutFooter extends React.Component<{}, LayoutFooterStates> {
  constructor(props: any) {
    super(props);
    this.state = { pathName: null };
  }

  public componentDidMount() {
    this.setState({ pathName: window.location.pathname });
    smoothscroll.polyfill();
  }

  public render() {
    return (
      <div className="layout-footer" id="layout-footer">
        <a
          href="javascript:void(0);"
          className="layout-footer-arrow-outer"
          onClick={() =>
            typeof window !== "undefined" &&
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        >
          <img src={ArrowBottom} alt="Arrow top" className="arrow-scroll" />
        </a>
        <div className="layout-footer-div">
          <div className="layout-footer-links" style={{ float: `left` }}>
            <div className="layout-footer-link">
              <a
                href="/about/"
                style={
                  this.state.pathName === "/about/"
                    ? { color: `#4cc5dc` }
                    : null
                }
              >
                <Trans i18nKey="ABOUT" />
              </a>
            </div>
            <div className="layout-footer-link">
              <a
                href="/services/"
                style={
                  this.state.pathName === "/services/"
                    ? { color: `#4cc5dc`, paddingRight: 0 }
                    : { paddingRight: `0` }
                }
              >
                {" "}
                <Trans i18nKey="SERVICES" />
              </a>
            </div>
          </div>
          <div style={{ textAlign: `left` }} className="legal-notice-p">
            <a href="/legal" style={{ color: `inherit` }} target="_blank">
              <Trans i18nKey="LEGAL" />
            </a>
          </div>
          <div className="layout-footer-bottom-div">
            <img src={Logo} alt="Surya_f_logo" className="layout-footer-logo" />
            <p className="layout-footer-address">
              <span>World Trade Center Lyon - Tour Oxygène</span>
              <span
                style={{
                  display: `block`
                }}
              >
                10-12 Boulevard Vivier-Merle
              </span>
              <span>69393 Lyon Cedex 03</span>
            </p>
            <a
              href="javascript:void(0);"
              className="layout-footer-arrow-inner"
              style={{ margin: `auto` }}
              onClick={() =>
                typeof window !== "undefined" &&
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
            >
              <img
                src={ArrowBottom}
                alt="Arrow Bottom"
                className="arrow-scroll"
                style={{ verticalAlign: `top` }}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default LayoutFooter;
