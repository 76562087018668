import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "gatsby";
import i18n from "i18next";
import React from "react";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import "../../i18n.js";
import "./layout.css";

interface LayoutNavbarProps extends WithTranslation {
  transition: boolean;
  dropdownLangOpen: boolean;
  dropdownServiceOpen: boolean;
  onMouseEnter: (dropdown: string) => void;
  onMouseLeave: (dropdown: string) => void;
  toggleServiceDropdown: () => void;
  toggleLang: () => void;
}

interface LayoutNavbarStates {
  hoverAbout?: boolean;
  hoverServices?: boolean;
  hoverDropdown?: boolean;
  hoverDropdownLang?: boolean;
  hoverLang?: boolean;
  pathname?: string;
}

class LayoutNavbar extends React.Component<
  LayoutNavbarProps,
  LayoutNavbarStates
> {
  constructor(props: LayoutNavbarProps) {
    super(props);
    this.state = {
      hoverAbout: false,
      hoverDropdown: false,
      hoverDropdownLang: false,
      hoverLang: false,
      hoverServices: false,
      pathname: ""
    };
  }

  public componentDidMount() {
    this.setState({
      hoverAbout: false,
      hoverServices: false,
      pathname: window.location.pathname
    });
  }

  public render() {
    return (
      <Nav
        style={{ padding: `0` }}
        className={!this.props.transition ? "navbar" : "navbar-hide"}
      >
        <NavItem
          className="navbar-item"
          onMouseOver={() => this.setState({ hoverAbout: true })}
          onClickCapture={() => this.setState({ hoverAbout: false })}
          onMouseLeave={() => this.setState({ hoverAbout: false })}
        >
          {" "}
          <NavLink
            href="/about/"
            style={
              this.state.pathname === "/about/"
                ? {
                    background: `rgba(76, 197, 220, 0.5)`,
                    height: `100%`,
                    paddingTop: `1.13rem`,
                    width: `100%`
                  }
                : {
                    height: `100%`,
                    paddingTop: `1.13rem`,
                    width: `100%`
                  }
            }
            onClick={() => {
              if (typeof localStorage !== "undefined") {
                localStorage.setItem("scrollPosition", (0).toString());
              }
            }}
          >
            <Trans i18nKey="ABOUT" />
          </NavLink>
        </NavItem>
        <NavItem
          className="navbar-item-toggle"
          style={{ border: `none`, outline: `none` }}
        >
          <div
            className="navbar-item-toggle"
            style={
              this.state.pathname === "/services/" ||
              (this.state.hoverServices && !this.state.hoverDropdown)
                ? {
                    background: `rgba(76, 197, 220, 0.5)`,
                    display: `block`
                  }
                : { background: `none`, outline: `none`, display: `block` }
            }
            onMouseOver={() => this.setState({ hoverServices: true })}
            onMouseLeave={() => this.setState({ hoverServices: false })}
          >
            <Dropdown
              isOpen={this.props.dropdownServiceOpen}
              toggle={this.props.toggleServiceDropdown}
              onMouseOver={() => this.props.onMouseEnter("dropdownServiceOpen")}
              onMouseLeave={() =>
                this.props.onMouseLeave("dropdownServiceOpen")
              }
            >
              <DropdownToggle
                variant="secondary"
                id="dropdown-basic"
                className="navbar-item-toggle"
                style={
                  this.props.dropdownServiceOpen && this.state.hoverDropdown
                    ? {
                        background: `none`,
                        border: `none`,
                        color: `#4cc5dc`,
                        marginLeft: `-1px`,
                        marginTop: `-1px`,
                        outline: `none`,
                        padding: `18px 0`
                      }
                    : this.props.dropdownServiceOpen
                    ? {
                        background: `none`,
                        border: `1px solid white`,
                        marginLeft: `-1px`,
                        marginTop: `-1px`,
                        outline: `none`,
                        padding: `17px 0`
                      }
                    : {
                        background: `none`,
                        border: `none`,
                        marginLeft: `-1px`,
                        marginTop: `-1px`,
                        outline: `none`,
                        padding: `18px 0`
                      }
                }
              >
                <Trans i18nKey="SERVICES" />
              </DropdownToggle>

              <DropdownMenu
                className="dropdownMenu"
                style={{ background: `rgba(0,0,0,0.8)`, marginTop: `0px` }}
                onMouseEnter={() =>
                  this.setState({ hoverDropdown: true, hoverServices: false })
                }
                onMouseLeave={() => this.setState({ hoverDropdown: false })}
              >
                <div className="dropdownItem" style={{ padding: `0` }}>
                  <DropdownItem
                    //   href="/services/#services1"
                    tag={Link}
                    to="/services/#services1"
                    className="dropdown-item-inner"
                    onClick={() => {
                      this.setState({ hoverServices: false });
                    }}
                  >
                    <Trans i18nKey="APPLICATIONS" />
                  </DropdownItem>
                </div>
                <div className="dropdownItem" style={{ padding: `0` }}>
                  <DropdownItem
                    //   href="/services/#services2"
                    tag={Link}
                    to="/services/#services2"
                    className="dropdown-item-inner"
                    onClick={() => {
                      this.setState({ hoverServices: false });
                    }}
                  >
                    <Trans i18nKey="TESTING" />
                  </DropdownItem>
                </div>
                <div className="dropdownItem" style={{ padding: `0` }}>
                  <DropdownItem
                    //   href="/services/#services3"
                    tag={Link}
                    to="/services/#services3"
                    className="dropdown-item-inner"
                    onClick={() => {
                      this.setState({ hoverServices: false });
                    }}
                  >
                    <Trans i18nKey="DATA SCIENTISTS" />
                  </DropdownItem>
                </div>
                <div className="dropdownItem" style={{ padding: `0` }}>
                  <DropdownItem
                    tag={Link}
                    to="/services/#services4"
                    className="dropdown-item-inner"
                    onClick={() => {
                      this.setState({ hoverServices: false });
                    }}
                  >
                    <Trans i18nKey="MICROSOFT DYNAMICS AX" />
                  </DropdownItem>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </NavItem>
        <div className="navbar-item-toggle">
          <Dropdown
            isOpen={this.props.dropdownLangOpen}
            toggle={this.props.toggleLang}
            style={
              this.props.dropdownLangOpen && !this.state.hoverDropdownLang
                ? {
                    background: `rgba(76, 197, 220, 0.5)`,
                    display: `block`
                  }
                : { background: `none`, outline: `none`, display: `block` }
            }
            onMouseOver={() => this.props.onMouseEnter("dropdownLangOpen")}
            onMouseLeave={() => this.props.onMouseLeave("dropdownLangOpen")}
          >
            <DropdownToggle
              variant="secondary"
              style={
                this.props.dropdownLangOpen && this.state.hoverDropdownLang
                  ? {
                      background: `none`,
                      border: `none`,
                      color: `#4cc5dc`,
                      marginLeft: `-1px`,
                      marginTop: `-1px`,
                      outline: `none`,
                      padding: `18px 0`
                    }
                  : this.props.dropdownLangOpen
                  ? {
                      background: `none`,
                      border: `1px solid white`,
                      marginLeft: `-1px`,
                      marginTop: `-1px`,
                      outline: `none`,
                      padding: `17px 0`
                    }
                  : {
                      background: `none`,
                      border: `none`,
                      marginLeft: `-1px`,
                      marginTop: `-1px`,
                      outline: `none`,
                      padding: `18px 0`
                    }
              }
              id="dropdown-basic"
              className="navbar-item-toggle"
              caret={false}
            >
              {i18n.language.toLocaleUpperCase() === "FR"
                ? "ENGLISH"
                : "français".toUpperCase()}
            </DropdownToggle>
            <DropdownMenu
              className="dropdownMenu"
              style={{ background: `rgba(0,0,0,0.8)` }}
              onMouseEnter={() =>
                this.setState({ hoverDropdownLang: true, hoverLang: false })
              }
              onMouseLeave={() => this.setState({ hoverDropdownLang: false })}
            >
              <div className="dropdownItem" style={{ padding: `0` }}>
                <DropdownItem
                  className="dropdown-item-inner"
                  style={{ width: `100%` }}
                  eventkey="ENGLISH"
                  onClick={() => {
                    i18n.changeLanguage("en-US");
                  }}
                >
                  ENGLISH
                </DropdownItem>
              </div>
              <div
                className="dropdownItem"
                style={{ padding: `0`, borderBottom: `none` }}
              >
                <DropdownItem
                  className="dropdown-item-inner"
                  style={{ width: `100%` }}
                  eventkey="FRENCH"
                  onClick={() => {
                    i18n.changeLanguage("fr");
                  }}
                >
                  FRANÇAIS
                </DropdownItem>
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Nav>
    );
  }
}

export default withTranslation()(LayoutNavbar);
