import "animate.css";
import "animate.css/animate.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, navigate } from "gatsby";
import React, { ReactNode, RefObject } from "react";
import Helmet from "react-helmet";
import { Trans, withTranslation, WithTranslation } from "react-i18next";
import { ParallaxProvider } from "react-scroll-parallax";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import { CSSTransition } from "react-transition-group";
import * as smoothscroll from "smoothscroll-polyfill";
import MenuIcon from "../../assets/menu_icon.png";
import logo from "../../assets/surya-digital-logo.png";
import Favicon from "../../assets/surya-logo-s-black.png";
import "../../i18n.js";
import "./layout.css";
import LayoutFooter from "./layoutFooter";
import LayoutNarrow from "./layoutNarrow";
import LayoutNavbar from "./layoutNavbar";

export interface LayoutProps extends WithTranslation {
  children: ReactNode;
}

export interface LayoutState {
  transition: boolean;
  render: boolean;
  dropdownServiceOpen: boolean;
  dropdownLangOpen: boolean;
  scroll: boolean;
  oldScroll: number;
  scrollDir: boolean;
}

export let scrollDir: boolean;

class Layout extends React.Component<LayoutProps, LayoutState> {
  private divRef: RefObject<HTMLElement> = React.createRef();
  private targetElement: HTMLElement = null;
  constructor(props: LayoutProps) {
    super(props);
    this.state = {
      dropdownLangOpen: false,
      dropdownServiceOpen: false,
      oldScroll: 0,
      render: false,
      scroll: false,
      scrollDir: true,
      transition: false
    };
  }

  public componentDidMount = () => {
    smoothscroll.polyfill();
    this.targetElement = this.divRef.current;
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener(
      "scroll",
      () => {
        localStorage.setItem("scrollPosition", window.scrollY.toString());
      },
      false
    );
    window.addEventListener(
      "load",
      () => {
        if (localStorage.getItem("scrollPosition") !== null) {
          window.scrollTo(
            0,
            parseInt(localStorage.getItem("scrollPosition"), 10)
          );
        }
      },
      false
    );
    let url = window.location.toString();
    url = url.split("#")[0];
  };

  public componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  public toggleLang = () => {
    this.setState(prevState => ({
      dropdownLangOpen: !prevState.dropdownLangOpen
    }));
  };

  public toggleServiceDropdown = () => {
    navigate("/services/");
    this.setState(prevState => ({
      dropdownServiceOpen: !prevState.dropdownServiceOpen
    }));
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("scrollPosition", (0).toString());
    }
  };

  public onMouseEnter = (dropdown: string) => {
    this.setState({ [dropdown]: true });
  };

  public onMouseLeave = (dropdown: string) => {
    this.setState({ [dropdown]: false });
  };

  public render() {
    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]');
    }
    return (
      <ParallaxProvider>
        <Helmet title="Surya Digital Accelerateur de croissance, Test de site internet, test d application IOS et test d application android, verification de site internet, verification d application, developpement d application IOS, developpement d application android, creation d application, centre de competence Dynamics AX, service de big data et data scientists, developpement logiciel bancaire">
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:ExtraLight"
            rel="stylesheet"
          />
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:Light"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
          />
          <link rel="icon" type="image/png" href={Favicon} />
          <link rel="shortcut icon" type="image/png" href={Favicon} />
        </Helmet>
        <div>
          <CSSTransition
            in={this.state.scroll}
            classNames="backdrop-animation"
            appear={false}
            timeout={500}
          >
            <div className="layout-header-backdrop" />
          </CSSTransition>
          <header
            className={"layout-header"}
            style={!this.state.transition ? { zIndex: 999999 } : { zIndex: 6 }}
          >
            <div className="surya-logo-div">
              {" "}
              <a
                href="/"
                className="navbar-logo-left"
                onClick={() => {
                  if (typeof localStorage !== "undefined") {
                    localStorage.setItem("scrollPosition", (0).toString());
                  }
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: `100%`,
                    width: `100%`
                  }}
                  alt="Surya Digital Logo"
                />{" "}
              </a>
            </div>
            <LayoutNavbar
              dropdownLangOpen={this.state.dropdownLangOpen}
              dropdownServiceOpen={this.state.dropdownServiceOpen}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              toggleLang={this.toggleLang}
              toggleServiceDropdown={this.toggleServiceDropdown}
              transition={this.state.transition}
            />

            <button
              className={
                this.state.transition
                  ? "navbar-sidebar-button"
                  : "navbar-sidebar-button-hide"
              }
              onClick={() => {
                this.handleClick();
              }}
              style={!this.state.transition ? { display: `none` } : null}
            >
              <img
                src={MenuIcon}
                alt="hamburger open"
                style={{
                  width: `100%`
                }}
              />
            </button>
          </header>
          <ScrollLock isActive={this.state.render} accountForScrollbars={false}>
            <main
              style={
                this.state.render
                  ? {
                      position: `absolute`,
                      width: `100%`
                    }
                  : { width: `100%` }
              }
            >
              {this.props.children}
              <LayoutFooter />
            </main>
          </ScrollLock>
          <CSSTransition
            in={this.state.transition}
            classNames="nav-animation"
            appear={false}
            timeout={500}
          >
            <div className="layout-narrow">
              <LayoutNarrow
                handleClick={this.handleClick}
                transition={this.state.transition}
              />
            </div>
          </CSSTransition>
        </div>
      </ParallaxProvider>
    );
  }

  public handleClick = () => {
    this.setState({
      transition: !this.state.transition
    });
    if (this.state.render === false) {
      this.setState({ render: true });
    } else {
      setTimeout(() => {
        this.setState({ render: !this.state.render });
      }, 350);
    }
  };

  private handleScroll = () => {
    if (window.scrollY === 0 && this.state.scroll === true) {
      this.setState({ scroll: false });
    } else if (window.scrollY > 0 && this.state.scroll !== true) {
      this.setState({ scroll: true });
    }

    const newScroll = window.pageYOffset;
    if (this.state.oldScroll - newScroll < 0) {
      this.setState({ scrollDir: true });
    } else if (this.state.oldScroll - newScroll > 0) {
      this.setState({ scrollDir: false });
    }
    scrollDir = this.state.scrollDir;
    this.setState({ oldScroll: newScroll });
  };
}

export default withTranslation()(Layout);
