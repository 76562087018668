import React, { CSSProperties } from "react";
import { Trans } from "react-i18next";
import "./layout.css";

interface ExcerptProps {
  excerpt: string;
  highlight?: string;
  animationId?: string;
  excerptEnd?: string;
  scrollDir?: boolean;
  style?: CSSProperties;
}

interface ExcerptStates {
  animationEnd: boolean;
  oldScroll: number;
  scrollDir: boolean;
}

class Excerpt extends React.Component<ExcerptProps, ExcerptStates> {
  constructor(props: ExcerptProps) {
    super(props);
    this.state = { scrollDir: true, oldScroll: 0, animationEnd: false };
  }

  public componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("animationstart", this.handleAnimationStart);
    window.addEventListener("animationend", this.handleAnimationEnd);
  };

  public componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  public render() {
    return (
      <div
        className="container-inner"
        style={
          this.state.scrollDir
            ? { animationName: `excerpt-shrink` }
            : { animationName: `excerpt-grow` }
        }
      >
        <div className="image-excerpt">
          <p style={{ display: `inline` }}>
            <Trans i18nKey={this.props.excerpt} />{" "}
          </p>
          <p style={{ display: `inline`, color: `#4cc5dc` }}>
            <Trans i18nKey={this.props.highlight} />
          </p>
          <p style={{ display: `inline` }}>
            {this.props.excerptEnd !== "." ? " " : ""}
            <Trans i18nKey={this.props.excerptEnd} />
          </p>
        </div>
      </div>
    );
  }

  private handleAnimationEnd = () => {
    this.setState({ animationEnd: true });
  };

  private handleAnimationStart = () => {
    this.setState({ animationEnd: false });
  };

  private handleScroll = () => {
    const newScroll = window.pageYOffset;
    if (this.state.oldScroll - newScroll < -100) {
      this.setState({ scrollDir: true });
    } else if (this.state.oldScroll - newScroll > 100) {
      this.setState({ scrollDir: false });
    }
    this.setState({ oldScroll: newScroll });
  };
}

export default Excerpt;
