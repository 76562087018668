import React, { CSSProperties, ReactNode } from "react";
import { Trans } from "react-i18next";
import "./layout.css";

const FeatureContainerThree = ({
  excerpt,
  style,
  headerIconSource
}: {
  excerpt?: string;
  style?: CSSProperties;
  headerIconSource: HTMLImageElement["src"];
}) => {
  return (
    <div className="feature-container-three">
      <img
        className="feature-container-image"
        style={style}
        alt="Feature Icon"
        src={headerIconSource}
      />
      <p>
        <Trans i18nKey={excerpt} />
      </p>
    </div>
  );
};

export default FeatureContainerThree;
